<template>
  <main>
    <!--  <PharmaciesBanner />
    <PharmaciesList />-->
    <PharmaciesBanner ref="pharmaciesBanner" />
    <PharmaciesList ref="pharmaciesList" />
  </main>
</template>

<script>
import PharmaciesBanner from "@/components/pharmacies/PharmaciesBanner";
import PharmaciesList from "@/components/pharmacies/PharmaciesList";
import { mapMutations } from "vuex";
export default {
  name: "PharmaciesView",
  components: {
    PharmaciesBanner,
    PharmaciesList

  },
  data() {
    return {
      pharmaciesList: null
    };
  },
  methods: {
    ...mapMutations(["setPharmaciesContext"])
  },
  mounted() {
    this.setPharmaciesContext(this.$refs.pharmaciesList);
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_pharmacies";
</style>
