var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"pharmacies-list"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pharmacies-list__count"},[_vm._v(" Знайдено аптек: "+_vm._s(_vm.pharmaciesList.length)+" ")]),_c('div',{staticClass:"row"},_vm._l((_vm.pharmaciesListDynamic),function(e,i){return _c('div',{key:'key' + i,staticClass:"col-xx-lg-3 col-x-lg-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 pharmacies-list__container"},[_c('PharmaciesItem',{attrs:{"name":e.title,"city":e.city,"address":e.address,"phone1":e.phones[0],"phone2":e.phones[1],"timework":e.timework.weekdays,"link":'https://www.google.com/maps/dir/' +
              e.position.lat +
              ',' +
              e.position.lng +
              '/' +
              e.position.lat +
              ',' +
              e.position.lng}})],1)}),0)]),(this.pharmaciesListDynamic.length !== this.pharmaciesList.length)?_c('div',{staticClass:"pharmacies-list__btn",on:{"click":function($event){return _vm.getPharmaciesListDynamic()}}},[_vm._v(" Показати більше ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }