<template>
  <section class="pharmacies-list">
    <div class="container">
      <div class="pharmacies-list__count">
        Знайдено аптек: {{ pharmaciesList.length }}
      </div>
      <div class="row">
        <div
          class="col-xx-lg-3 col-x-lg-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 pharmacies-list__container"
          v-for="(e, i) in pharmaciesListDynamic"
          :key="'key' + i"
        >
          <PharmaciesItem
            :name="e.title"
            :city="e.city"
            :address="e.address"
            :phone1="e.phones[0]"
            :phone2="e.phones[1]"
            :timework="e.timework.weekdays"
            :link="
              'https://www.google.com/maps/dir/' +
                e.position.lat +
                ',' +
                e.position.lng +
                '/' +
                e.position.lat +
                ',' +
                e.position.lng
            "
          />
        </div>
      </div>
    </div>
    <div
      class="pharmacies-list__btn"
      @click="getPharmaciesListDynamic()"
      v-if="this.pharmaciesListDynamic.length !== this.pharmaciesList.length"
    >
      Показати більше
    </div>
  </section>
</template>

<script>
import PharmaciesItem from "@/components/pharmacies/PharmaciesItem";
import { mapGetters } from "vuex";
export default {
  name: "PharmaciesList",
  data() {
    return {
      pharmaciesList: [],
      pharmaciesListDynamic: [],
      showShowBtn: false
    };
  },
  components: {
    PharmaciesItem
  },
  computed: {
    ...mapGetters(["getPharmaciesList"])
  },
  methods: {
    getPharmacies() {
      this.pharmaciesList = this.getPharmaciesList;

      if (this.pharmaciesList.length > 20) {
        this.showSwowBtn = true;
        this.pharmaciesListDynamic = this.pharmaciesList.slice(0, 20);
      } else {
        this.showSwowBtn = false;
        this.pharmaciesListDynamic = this.pharmaciesList;
      }
    },
    getPharmaciesListDynamic() {
      var curLength = this.pharmaciesListDynamic.length;
      var totalLength = this.pharmaciesList.length;
      if (totalLength > curLength + 20) {
        this.pharmaciesListDynamic = this.pharmaciesList.slice(
          0,
          curLength + 20
        );
      } else {
        if (totalLength >= curLength) {
          this.pharmaciesListDynamic = this.pharmaciesList.slice(
            0,
            totalLength
          );
        }
      }
    }
  },
  mounted() {
    this.getPharmacies();
  }
};
</script>
